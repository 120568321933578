// @ts-check

import {
	connect as wsapiConnect,
	disconnect as wsapiDisconnect,
	getSocket,
} from '@technomiam/wsapi-client';

const MIXING_PATH = '/mixing';

/** @type {string | undefined} */
let endpoint;

/**
 * @typedef {import('../../../../../webrtc-router/src/lib/mixer/Formatter')
* 	.ChannelMixerStatus} ChannelMixerStatus
* @typedef {import('../../../../../webrtc-router/src/lib/mixer/Channel')
* 	.SourceToCreate} SourceToCreate
* */

/**
 * @param {{
 *  baseUrl: string;
 *  options: Parameters<typeof wsapiConnect>[1];
 * }} param0
 * @returns {Promise<any}
 */
export const connect = async ({ baseUrl, options }) => {
	endpoint = `${baseUrl}${MIXING_PATH}`;
	return wsapiConnect(endpoint, options);
};

/**
 * @returns {void}
 */
export const disconnect = () => {
	if (!endpoint) return;
	wsapiDisconnect(endpoint);
};

/**
 * @returns {any}
 */
export const socket = () => {
	if (!endpoint) return undefined;
	return getSocket(endpoint)();
};

/**
 * @param {string} hashtag
 * @returns {Promise<() => Promise<void>>}
 */
export async function join(hashtag) {
	const sock = socket();

	const emitjoin = () => new Promise((resolve, reject) => {
		sock.emit('join.control', { hashtag }, ({ error, data }) => {
			if (error) {
				console.error(`Error while joining signaling of channel [${hashtag}]`);
				reject(error);
			} else {
				console.info(`joined ${data.hashtag}`);
				resolve(data);
			}
		});
	});

	const onReconnect = () => emitjoin();
	sock.on('reconnect', onReconnect);

	const emitleave = () => new Promise((resolve, reject) => {
		sock.off('reconnect', onReconnect);
		sock.emit('leave', { hashtag }, ({ error, data }) => {
			if (error) {
				console.error(`Error while leaving signaling of channel [${hashtag}]`);
				reject(error);
			} else {
				console.info(`leaved ${data.hashtag}`);
				resolve();
			}
		});
	});

	await emitjoin();

	return async () => emitleave();
}

export function clearLayer(hashtag, layer) {
	socket().emit('layer', { hashtag, layer });
}

export function setSource(hashtag, source, layer) {
	socket().emit('source', { hashtag, source, layer });
}

export function startRecording(hashtag, durationMinutes) {
	socket().emit('record.start', { hashtag, durationMinutes });
}

export function stopRecording(hashtag) {
	socket().emit('record.stop', { hashtag });
}

export function onRecordingChange(listener) {
	const sock = socket();
	sock.on('recording.change', listener);
	return function offRecordingChange() {
		sock.off('recording.change', listener);
	};
}

export function onRecordingNew(listener) {
	const sock = socket();
	sock.on('recording.new', listener);
	return function offRecordingChange() {
		sock.off('recording.new', listener);
	};
}

export function start(hashtag) {
	socket().emit('start', { hashtag });
}

export function onEventStatus(listener) {
	const sock = socket();
	sock.on('event.status', listener);
	return function offEventStatus() {
		sock.off('event.status', listener);
	};
}

export function status(hashtag) {
	socket().emit('publish.status', { hashtag });
}

export async function notifyNewDrawing(hashtag, drawing) {
	socket().emit('event.drawing.new', { hashtag, drawing });
}

export async function notifyClearDrawing(hashtag) {
	socket().emit('event.drawing.clear', { hashtag });
}

export async function notifyClearAllDrawings(hashtag) {
	socket().emit('event.drawing.clear.all', { hashtag });
}

export async function notifyToggleDrawings(hashtag, allowedUser) {
	socket().emit('event.drawing.toggle', { hashtag, allowedUser });
}

export async function notifyToggleDrawingsBackground(hashtag, data) {
	socket().emit('event.drawing.background.toggle', { hashtag, data });
}

export async function notifyKeyConfig(hashtag, config) {
	socket().emit('event.key.config', { hashtag, config });
}
export function togglePlayPauseVideoSource(hashtag, source) {
	socket().emit('event.source.toggle.play', { hashtag, source });
}

export function toggleLoopVideoSource(hashtag, source) {
	socket().emit('event.source.toggle.loop', { hashtag, source });
}

export function setSourceMuted(hashtag, source, muted) {
	socket().emit('event.source.muted.changed', { hashtag, source, muted });
}

export function setSourceVolume(hashtag, source, volume) {
	socket().emit('event.source.volume.changed', { hashtag, source, volume });
}

export function setSourceTimecodes(hashtag, source, timecodes) {
	socket().emit('event.source.timecodes.changed', { hashtag, source, timecodes });
}

export function setSourceSpeed(hashtag, source, speed) {
	socket().emit('event.source.speed.changed', { hashtag, source, speed });
}

export function setSourceProgresstime(hashtag, source, progresstime) {
	socket().emit('event.source.progresstime.changed', { hashtag, source, progresstime });
}

export function changePiPPosition(hashtag, x, y, pipLayer, width, height) {
	socket().emit('event.pip.position.change', { hashtag, x, y, pipLayer, width, height });
}

export function publishModeChange(hashtag, mode) {
	socket().emit('publish.mode.change', { hashtag, mode });
}

export function publishAutomaticSwitchChange(hashtag, isEnabled) {
	socket().emit('publish.automaticswitch.change', { hashtag, isEnabled });
}

export function notifyCrop(hashtag, rect) {
	socket().emit('event.crop.change', { hashtag, rect });
}

export function notifyDrawNickname(hashtag, isEnabled) {
	socket().emit('event.drawnickname.change', { hashtag, isEnabled });
}

export function notifyPipMode(hashtag, mode, sources) {
	socket().emit('event.pipmode.change', { hashtag, mode, sources });
}

export function publishGfxUpdate(hashtag, gfx) {
	socket().emit('publish.gfx.update', { hashtag, gfx });
}

export function publishTextUpdate(hashtag, text) {
	socket().emit('publish.text.update', { hashtag, text });
}

export function publishSceneApply(hashtag, scene) {
	socket().emit('publish.scene.apply', { hashtag, scene });
}

export function publishAutomaticFillChange(hashtag, isEnabled) {
	socket().emit('publish.automaticfill.change', { hashtag, isEnabled });
}

export function notifyCanvasLogoChange(hashtag, showDefaultLogo, logoUrl) {
	socket().emit('event.canvaslogo.change', { hashtag, showDefaultLogo, logoUrl });
}

export function notifySourcesShapeChange(hashtag, sourceShape) {
	socket().emit('event.sourcesShape.change', { hashtag, sourceShape });
}

export function notifySourceParticipantOffersChange(hashtag, sources) {
	socket().emit('event.sourceparticipantoffers.change', { hashtag, sources });
}

export function onHostMuteParticipantCam(hashtag, sourceId, isMuted) {
	socket().emit('event.host.mute.participant.cam', { hashtag, sourceId, isMuted });
}

/**
 * @param {string} hashtag
 * @param {SourceToCreate} firstSource
 * @param {SourceToCreate} secondSource
 * @param {{ type: string, duration: number }} transition
 * @param {number} transitionAdvancement
 */
export function applyPreview(
	hashtag,
	firstSource,
	secondSource,
	transition,
	transitionAdvancement,
) {
	socket().emit('event.applyPreview', { hashtag, firstSource, secondSource, transition, transitionAdvancement });
}

// @ts-check
/* eslint-disable react/prop-types */
import { useMemo, useState } from 'react';

import { ControlroomModeContext, ControlroomModes } from './Context';

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} ControlroomModeProviderProps
 */

export const ControlroomModeProvider = (
	/** @type {ControlroomModeProviderProps} */
	{ children },
) => {
	const [controlroomMode, setControlroomMode] = useState(ControlroomModes.PRO);

	const value = useMemo(() => ({
		controlroomMode,
		setControlroomMode,
	}), [
		controlroomMode,
		setControlroomMode,
	]);

	return (
		<ControlroomModeContext.Provider value={value}>
			{children}
		</ControlroomModeContext.Provider>
	);
};

//@ts-check
import { createContext, useContext } from 'react';

/**
 * @typedef {{
 * setSelectedSource: (source: any) => void,
 * selectionMode: string,
 * setSelectionMode: (mode: string) => void,
 * selectedSource1: any,
 * selectedSource2: any,
* }} IControlroomProSourceSelectorContext
*/

export const SELECTION_MODE = {
	Source_1: 'source_1',
	Source_2: 'source_2',
	None: 'none',
};

export const ControlroomProSourceSelectorContext = createContext(
	/** @type {IControlroomProSourceSelectorContext | undefined} */({}),
);

export const useControlroomProSourceSelector = () => {
	const ctx = useContext(ControlroomProSourceSelectorContext);
	// type guard (removes undefined type)
	if (!ctx) {
		throw new Error('useControlroomProSourceSelector must be used within a ControlroomProSourceSelectorProvider');
	}
	return ctx;
};

import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAsyncCallback } from 'react-async-hook';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { isDesktop } from '../../../lib/userAgent';
import { ButtonPillOutline } from '../../Button';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareRoleInput } from '../RoleInput';
import { ShareRecipientType, ShareTabType } from '../Share.helper';
import FormSubmit from '../../Form/Submit';
import { AlertTimeout } from '../../Alert/Timeout';
import { UnifiedShareInput } from './UnifiedShareInput';
import { FormLabel } from '../../Form/Label';

const formSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		message: {
			type: 'string',
			maxLength: 100,
		},
		users: {
			type: 'array',
			minItems: 1,
		},
	},
};

const getInitialData = (message) => ({
	users: [],
	message: message || '',
});

const projectName = import.meta.env.VITE_PROJECT;

export const ShareTabUsers = ({
	alreadySharedUsers,
	defaultMessage,
	inputColor,
	isLoading,
	onShare,
	roles,
	selectedRole,
	setSelectedRole,
	setSuggestionsSearchValue,
	shareButtonTranslationKey,
	showSuccessMessage,
	studio,
	suggestions,
}) => {
	const { t } = useTranslation();
	const [formData, setFormData] = useState(getInitialData(defaultMessage));
	const [currentInput, setCurrentInput] = useState('');
	const [recipients, setRecipients] = useState([]);
	const [recipientType, setRecipientType] = useState(ShareRecipientType.USER);

	const searchLabel = selectedRole === ResourceAccessRole.OPERATOR
		? t('Share.Tab.Users.searchOperator')
		: t('Share.Tab.Users.unifiedSearch', { projectName });

	const truncatedSearchLabel = <small className="text-truncate d-inline-block w-100 pt-2">{searchLabel}</small>;

	const alreadySharedUsersIds = useMemo(() => alreadySharedUsers.map((p) => {
		if (p.type === ShareRecipientType.USER || p.type === ShareRecipientType.USERS) {
			return p.user._id;
		}
		return null;
	}), [alreadySharedUsers]);

	const clear = () => {
		setCurrentInput('');
		setRecipients([]);
		setFormData((s) => ({ ...s, users: [] }));
	};

	const handleSubmitAsync = useAsyncCallback(async () => {
		let participants = [];

		switch (recipientType) {
		case ShareRecipientType.EMAIL:
			participants = [{
				type: ShareRecipientType.EMAIL,
				email: recipients,
				message: formData.message,
			}];
			break;

		case ShareRecipientType.SMS:
			participants = [{
				type: ShareRecipientType.SMS,
				phoneNumber: recipients,
				message: formData.message,
			}];
			break;

		case ShareRecipientType.USER:
		default:
			participants = recipients.map((user) => ({
				type: ShareRecipientType.USER,
				user: user.user,
				message: formData.message,
			}));
			break;
		}

		await onShare(participants, selectedRole);
		clear();
	});

	useEffect(() => {
		clear();
	}, [selectedRole]);

	const handleUnifiedInputChange = ({ type, value }) => {
		switch (type) {
		case ShareRecipientType.EMAIL:
			setRecipientType(ShareRecipientType.EMAIL);
			setRecipients(value);
			break;
		case ShareRecipientType.SMS:
			setRecipientType(ShareRecipientType.SMS);
			setRecipients(value);
			break;
		case ShareRecipientType.USER:
		default:
			setRecipientType(ShareRecipientType.USER);
			setRecipients(Array.isArray(value) ? value.map((user) => ({
				...user,
				_id: user.value,
			})) : []);
			break;
		}
	};

	const isEmpty = !recipients?.length;
	const isActionDisabled = isEmpty || handleSubmitAsync.loading;

	return (
		<Form
			className="ShareTabUsers overflow-x-hidden"
			data={formData}
			onSubmit={handleSubmitAsync.execute}
			schema={recipientType === ShareRecipientType.USERS ? formSchema : {}}
		>
			{showSuccessMessage && handleSubmitAsync.status === 'success' && (
				<AlertTimeout color="success" onTimeout={handleSubmitAsync.reset} timeoutTime={3000}>
					{t('Share.shareSuccessfullySent')}
				</AlertTimeout>
			)}
			{handleSubmitAsync.error && (
				<AlertTimeout color="danger" onTimeout={handleSubmitAsync.reset}>
					{t('Global.error')}
				</AlertTimeout>
			)}
			<Row className="w-100">
				<Col xs={10} className="m-0 pr-2">
					<UnifiedShareInput
						searchLabel={truncatedSearchLabel}
						formData={formData}
						setFormData={setFormData}
						alreadySharedUsersIds={alreadySharedUsersIds}
						inputColor={inputColor}
						isLoading={isLoading}
						studio={studio}
						suggestions={suggestions}
						setSuggestionsSearchValue={setSuggestionsSearchValue}
						handleChange={handleUnifiedInputChange}
						selectedRole={selectedRole}
						setCurrentInput={setCurrentInput}
						currentInput={currentInput}
					/>
				</Col>
				<Col xs={2} className="mt-4 p-0 pl-lg-2">
					{selectedRole !== ResourceAccessRole.PUBLIC && (
						<ShareRoleInput
							value={selectedRole}
							onChange={(role) => setSelectedRole(role)}
							roles={roles}
							shareTabType={ShareTabType.USERS}
						/>
					)}
				</Col>
			</Row>
			<div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center pl-1">
				<div className="d-flex justify-content-end mb-3">
					<ButtonPillOutline
						className="mr-2"
						color={inputColor}
						disabled={isActionDisabled}
						onClick={clear}
					>
						{t('Share.Buttons.clear')}
					</ButtonPillOutline>
					<FormSubmit
						className="btn-pill shadow-none"
						disabled={isActionDisabled}
						loading={handleSubmitAsync.loading}
						title={t('Share.Tab.Users.sendInvitationToUsers')}
					>
						{t(shareButtonTranslationKey)}
					</FormSubmit>
				</div>
			</div>
			{isDesktop && (
				<FormGroup>
					<FormLabel>{t('Share.Message.message')}</FormLabel>
					<Field
						className={`Share_InviteMessage bg-${inputColor} content-${inputColor} rounded-1 border-${inputColor}`}
						component={Input}
						name="message"
						onChange={(e) => setFormData({ ...formData, message: e.target.value })}
						placeholder={t('Share.Message.addCustomMessage')}
						rows="3"
						type="textarea"
						value={formData.message}
					/>
					<FieldError
						errorMessages={{
							maxLength: () => t('Share.Message.messageIsTooLong'),
						}}
						name="message"
					/>
				</FormGroup>
			)}
		</Form>
	);
};

ShareTabUsers.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultMessage: PropTypes.string,
	inputColor: PropTypes.oneOf(['dark', 'light']),
	isLoading: PropTypes.bool,
	onShare: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
	setSuggestionsSearchValue: PropTypes.func,
	shareButtonTranslationKey: PropTypes.string.isRequired,
	showSuccessMessage: PropTypes.bool,
	studio: PropTypes.shape({
		owner: PropTypes.shape({
			_id: PropTypes.string,
		}),
	}),
	suggestions: PropTypes.arrayOf(PropTypes.shape({})),
};

ShareTabUsers.defaultProps = {
	defaultMessage: '',
	inputColor: 'dark',
	isLoading: false,
	setSuggestionsSearchValue: undefined,
	showSuccessMessage: true,
	studio: { owner: { _id: undefined } },
	suggestions: undefined,
};

// @ts-check
import { createContext, useContext } from 'react';

export const ControlroomModes = {
	BASIC: 'BASIC',
	PRO: 'PRO',
};

/**
 * @typedef {{
 * controlroomMode: string,
 * setControlroomMode: (mode: string) => void,
* }} IControlroomModeContext
*/

export const ControlroomModeContext = createContext(
	/** @type {IControlroomModeContext | undefined} */({}),
);

export const useControlroomMode = () => {
	const ctx = useContext(ControlroomModeContext);
	// type guard (removes undefined type)
	if (!ctx) {
		throw new Error('useControlroomMode must be used within a ControlroomModeProvider');
	}
	return ctx;
};
